<template>
  <b-card>
    <g-table
      ref="ClassRoom-table"
      :items="items"
      :columns="tableColumns"
      :noAction="true"
      perPage="25"
      :totalRows="totalRows"
      @filtered="onFiltered"
      :createButton="{ visiable: true , permission: 'addClasses'}"
      :searchInput="{ visiable: true }"
      @on-create="
        (v) => {
          openModal({ englishName: '' });
        }
      "
    >
      <template #actions="{ item }">
        <div class="text-nowrap">
          <b-button
            v-b-tooltip.hover.top="$t('preview')"
            v-permission="'editClasses'"
            data-action-type="preview"
            variant="custom"
            class="btn-icon"
            size="sm"
            @click="openModal(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-prev-icon`"
              icon="EyeIcon"
              class="mx-25 clickable"
              :hidden="true"
            />
          </b-button>
          <b-button
            v-b-tooltip.hover.top="$t('edit')"
            v-permission="'editClasses'"
            data-action-type="edit"
            variant="custom"
            class="btn-icon"
            size="sm"
            @click="openModal(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-preview-icon`"
              icon="EditIcon"
              class="mx-25 clickable"
            />
          </b-button>
          <b-button
            v-b-tooltip.hover.top="$t('delete')"
            v-permission="'deleteClasses'"
            data-action-type="delete"
            variant="flat-danger"
            class="btn-icon"
            size="sm"
            @click="remove(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-delete-icon`"
              icon="TrashIcon"
              stroke="red"
              class="danger"
            />
          </b-button>
        </div>
      </template>
    </g-table>
    <b-sidebar
      id="sidebar-edit"
      ref="sidebar"
      no-close-on-backdrop
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      left
      @hidden="
        () => {
          selectedItem = {};
        }
      "
    >
      <template #default>
        <div
          class="d-flex justify-content-between bg-primary align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0 text-white">
            {{ selectedItem.id > 0 ? $t("editClassRoom") : $t("newClassRoom") }}
          </h5>
        </div>
        <b-card>
          <g-form @submit="save">
            <b-row>
              <b-col
                v-if="selectedItem.id > 0"
                md="12"
              >
                <label
                  style="font-size: 14px; margin-bottom: 7px"
                  for="customer"
                >
                  {{ $t("code") }}
                </label>
                <!-- code  -->
                <g-field
                  id="code"
                  type="number"
                  :value.sync="selectedItem.code"
                  name="code"
                  disabled
                />
              </b-col>

              <b-col md="12">
                <!-- arabicName  -->
                <g-field
                  id="arabicName"
                  ref="arabicName"
                  rules="required"
                  :value.sync="selectedItem.arabicName"
                  name="arabicName"
                  label-text="arabicName"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <g-field
                  id="englishName"
                  ref="englishName"
                  :value.sync="selectedItem.englishName"
                  name="englishName"
                  label-text="englishName"
                />
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <g-field
                    :value.sync="selectedItem.stageId"
                    :dir="isRight ? 'rtl' : 'ltr'"
                    label-text="stage"
                    rules="required"
                    field="select"
                    name="stage"
                    :options="stages"
                    label="arabicName"
                    @change="
                      () => {
                        this.selectedItem.row = null;
                      }
                    "
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <g-field
                    :value.sync="selectedItem.row"
                    :dir="isRight ? 'rtl' : 'ltr'"
                    label-text="row"
                    rules="required"
                    field="select"
                    name="row"
                    :options="rows"
                    :filter-fn="(item) => item.stageId === selectedItem.stageId"
                    label="arabicName"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <g-field
                    field="number"
                    rules="required"
                    :value.sync="selectedItem.studentsCount"
                    name="studentsCount"
                    type="number"
                    label-text="studentsCount"
                  />
                </b-form-group>

              </b-col>
              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="12">
                <h5> {{ $t('specialForStudentSucess') }} </h5>
                <span style="font-size: 12px; color: darkgray; letter-spacing: 1.1px;"> {{ $t('nextLevelHintMessage', {level: $t('class')}) }} </span>
                <b-form-group class="mt-1">
                  <g-field
                    field="select"
                    label="displayName"
                    label-text="nextClassroom"
                    name="nextClassroom"
                    :dir="isRight ? 'rtl' : 'ltr'"
                    :value.sync="selectedItem.nextClassroom"
                    :filter-fn="(item) => item.row === helper.nextRow"
                    :options="items"
                    :disabled="selectedItem.row > 0 && !helper.nextRow"
                  />
                  <span
                    v-show="selectedItem.row > 0 && !helper.nextRow"
                    style="font-size: 12px; letter-spacing: 1.1px; color: #c75555; display: block; margin-top: -5px;"
                  >
                    {{ $t('cantChooseNextClassHint', {currentRow: helper.currentRowName}) }}
                  </span>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                cols="12"
                class="d-flex justify-content-end"
              >
                <b-button
                  v-permission="$route.meta.permission"
                  class="mx-1"
                  type="submit"
                  variant="primary"
                  data-action-type="save"
                >
                  {{ $t("save") }}
                </b-button>
                <b-button
                  class="mx-1"
                  variant="secondary"
                  @click="closeModal"
                >
                  {{ $t("Close") }}
                </b-button>
              </b-col>
            </b-row>
          </g-form>
        </b-card>
      </template>
    </b-sidebar>
  </b-card>
</template>

<script>
import GTable from '@/pages/Shared/Table.vue';

export default {
  components: {
    GTable,
  },
  data() {
    return {
      searchQuery: '',
      currentPage: 1,
      perPage: 25,
      isTableBusy: false,
      totalRows: 0,
      filterOn: [],
      filter: {
        orderClause: null,
      },
      items: [],
      rows: [],
      stages: [],
      classrooms: [],
      selectedItem: {},
      modal: false,

      helper: {
        selectedRow: null,
        currentRowName: ''
      }
    };
  },
  computed: {
    tableColumns() {
      return [
        { key: 'code', label: this.$t('code'), sortable: true },
        { key: 'arabicName', label: this.$t('class'), sortable: true },
        { key: 'stageArabicName', label: this.$t('stage'), sortable: true },
        { key: 'rowArabicName', label: this.$t('row'), sortable: true },
        { key: 'studentsCount', label: this.$t('studentsCount'), sortable: true },
        { key: 'actions' },
      ];
    },
  },

  watch: {
    'selectedItem.row'(newVal) {
      if (newVal) {
        const selectedRow = this.rows.find((x) => x.id === newVal);
        this.helper.nextRow = selectedRow.nextRow
        this.helper.currentRowName = selectedRow.arabicName
      } else {
        this.selectedItem.nextClassroom = null;
        this.helper.nextRow = null;
        this.helper.currentRowName = ''
      }
    }
  },

  mounted() {
    this.getData();
    this.getItems();
  },

  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getData() {
      this.get({ url: 'stages' }).then((data) => {
        this.stages = data;
      });
      this.get({ url: 'rows/getRowsLookupWithEducationlIds' }).then((data) => {
        this.rows = data;
      });
    },
    getItems() {
      this.get({ url: 'Classrooms' }).then((data) => {
        this.items = data;
        this.items.forEach(element => {
          element.displayName = element.arabicName.concat(' - ', element.rowArabicName)
        })
      })
    },
    save() {
      if (this.selectedItem.id > 0) {
        this.update({
          url: 'classRooms',
          data: this.selectedItem,
          id: this.selectedItem.id,
        }).then(() => {
          this.closeModal();
          this.doneAlert({ text: this.$t('updatedSuccessfully') });
          this.getItems();
        });
      } else {
        this.create({ url: 'classRooms', data: this.selectedItem }).then(() => {
          this.closeModal();
          this.doneAlert({ text: this.$t('savedSuccessfully') });
          this.getItems();
        });
      }
    },
    remove(item) {
      this.confirmAction(
        {
          text: this.$t('areYouSureYouWantToDelete'),
        },
        () => {
          this.delete({ url: 'classRooms', id: item.id }).then(() => {
            this.doneAlert({ text: this.$t('deletedSuccessfully') });
            this.getItems();
          });
        }
      );
    },

    closeModal() {
      this.$refs.sidebar.hide();
    },
    openModal(item) {
      this.selectedItem = { ...item };
      this.$root.$emit('bv::toggle::collapse', 'sidebar-edit')
    },
  },
};
</script>

<style></style>
